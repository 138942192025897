@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&family=Outfit:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

/* html, body {
  height: 100%;
} */


body {
  margin: 0;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  /* background-image: url('../src/assests/moon.webp'); */
  background-color: #052131;
  /* background-size: cover;
  background-attachment: fixed; */
}

/* .carousel-container  {
    overflow: hidden;
    position: relative;
    min-height: 30rem;
    width: 100%;
}


.carousel-track {
  display: flex;
  position: absolute;
  left: 0;
  gap: 1rem;
  width: 500%;
  height: 100%;
  animation: slide 20s linear infinite;
  background-color: blue;
  display: flex;
  &:hover {
    animation-play-state: paused;
}
  
}

@media only screen and (max-width: 600px) {
  .carousel-track{
    width: 800%;

  }
}

@keyframes slide {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
} */

.img-container{
  background-image: url('https://firebasestorage.googleapis.com/v0/b/taba-5c32a.appspot.com/o/media%2Fcarousel3.webp?alt=media&token=fc83739c-653b-4442-ac42-f8926bb387ca');
  background-position: center;
  background: linear-gradient(90deg, rgba(52,50,73,1) 0%, rgba(5,5,51,1) 55%, rgba(10,30,34,1) 100%);
}

.mirror-image {
  transform: scaleX(-1);
}

p{
  font-family: 'inter', sans-serif;
  color: 'rgb(230,230,230)';
}

.video-container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 aspect ratio (height/width) */
  overflow: hidden;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
